<template>
    <div>
        <header-portrate style="display:none" />

    <div class="m-2" style="border:1px solid #000;background:lightgoldenrodyellow">
        <posHeader />
        <v-row class="m-2">
            <v-col cols="12" style="position:relative">
                <div>
                    <b-button style="font-size:1.1rem;border:2px solid #000 !important;background:black !important;width:145px;color:#fff;" class="mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/report/daily'})">{{lang.daily_report}}</b-button> 
                    <b-button style="font-size:1.1rem;border:2px solid #000 !important;background:#d7b100 !important;width:145px;color:#fff;display:none;" class="mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/report/vat-report'})">{{lang.vat_report}}</b-button> 
                    <b-button style="font-size:1.1rem;border:2px solid #000 !important;background:#d7b100 !important;width:145px;color:#fff;display:none;" class="mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/report/items-max'})">{{lang.max_items_sales}}</b-button> 
                    <b-button style="font-size:1.1rem;border:2px solid #000 !important;background:#d7b100 !important;width:145px;color:#fff;display:none;" class="mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/report/items-less'})">{{lang.less_items_sates}}</b-button> 
                    <b-button style="font-size:1.1rem;border:2px solid #000 !important;background:#d7b100 !important;width:145px;color:#fff;display:none;" class="mytab2 btn btn-sm btn-success m-2" @click="$router.push({path: '/pos/report/items-zero'})">{{lang.zero_items}}</b-button> 
                </div>

                <!-- <b-button variant="success"  
                class="btn-sm addemployeecl addButtom" 
                :style="`width:145px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat @click="resetTitle()" 
                v-b-modal.addEmployee 
                id="multiply-button"
                color="success">
                <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_employee}}</div>
                </b-button> -->
            </v-col>                  
            
            <v-col cols="12" md="12" sm="12" style="padding-top:0px;position:relative">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        
                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group style="margin-top:-5px">
                                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getInvoice()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                <!-- <b-button variant="success"  
                    class="btn-sm addemployeecl addButtom" 
                    :style="`width:155px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat @click="addExp()" 
                    v-b-modal.add_genexpense
                    id="multiply-button"
                    color="success">
                    <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_general_expense}}</div>
                </b-button> -->
            </v-col>
            
            <v-col cols="12" md="12" sm="12" style="padding-top:0px;">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="13" class="text-center backBlack" style="border-bottom:7px solid #fff !important;">{{lang.income_expense_report}}</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack" rowspan="2">{{lang.date}}</th>
                            <th class="text-center backGreen" rowspan="2">{{lang.income}}</th>
                            <th class="text-center" colspan="3" style="border:2px solid #fff !important;background:blue !important;color:#fff !important;">{{lang.employees}}</th>
                            <th class="text-center backRed" rowspan="2">{{lang.credit_note}}</th>
                            <th class="text-center backRed" colspan="3">{{lang.purchase_and_expenses}}</th>
                            <th class="text-center backBlack" colspan="2">{{lang.payment_type}}</th>
                            <th class="text-center backBlack" rowspan="2">{{lang.balance}}</th>
                            <th class="text-center backBlack" rowspan="2">{{lang.details}}</th>
                        </tr>
                        <tr>
                            <th class="text-center" style="border:2px solid #fff !important;background:blue !important;color:#fff !important;">{{lang.salaries}}</th>
                            <th class="text-center" style="border:2px solid #fff !important;background:blue !important;color:#fff !important;">{{lang.emp_custody}}</th>
                            <th class="text-center" style="border:2px solid #fff !important;background:blue !important;color:#fff !important;">{{lang.paid_in_advanced}}</th>
                            <th class="text-center backRed">{{lang.purchase}}</th>
                            <th class="text-center backRed">{{lang.expnese}}</th>
                            <th class="text-center backRed">{{lang.vendor_payments}}</th>
                            <th class="text-center backBlack">{{lang.cash}}</th>
                            <th class="text-center backBlack">{{lang.other}}</th>
                        </tr>
                        <tr>
                            <th class="text-left" colspan="11">{{lang.old_balance}}</th>
                            <th class="text-center">{{lastBalance}}</th>
                            <th class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in report" :key="index">
                            <td class="text-center" style="direction:ltr;">{{item.date}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.income,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.salaries,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.emp_custody,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.paid_in_advanced,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.credit_note,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.purchase,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.outcome,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;background:lightgreen">{{$convertToComa(item.vendor_payments,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;">{{$convertToComa(item.cash,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;">{{$convertToComa(item.others,comaMoney)}}</td>
                            <td class="text-center" style="direction:ltr;" v-html="$convertToComa(parseFloat(item.balance),comaMoney)"></td>
                            <td  @click="ViewItem(item)" style="cursor: pointer;margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                            <div class="viewBTN">{{lang.details}}</div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="backBlack text-center">{{lang.total}}</th>
                            <th class="backGreen text-center">{{totals.inTotal}} </th>
                            <th class="backRed text-center" style="background:blue !important;color:#fff !important;">{{totals.salaries}} </th>
                            <th class="backRed text-center" style="background:blue !important;color:#fff !important;">{{totals.emp_custody}} </th>
                            <th class="backRed text-center" style="background:blue !important;color:#fff !important;">{{totals.paid_in_advanced}} </th>
                            <th class="backRed text-center">{{totals.credit_note}} </th>
                            <th class="backRed text-center">{{totals.purchase}} </th>
                            <th class="backRed text-center">{{totals.outTotal}} </th>
                            <th class="backRed text-center">{{totals.vendor_payments}} </th>
                            <th class="backBlack text-center">{{totals.cash}} </th>
                            <th class="backBlack text-center">{{totals.others}} </th>
                            <th class="backEmpty text-center"></th>
                            <th class="backEmpty text-center"></th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="12" sm="12" style="padding-top:0px;">
                <v-row>
                    <v-col cols="12" md="6" v-for="(item,index) in inOutState.periodPayTypes" :key="index">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.payment_type }}</th>
                                    <th class="text-center backBlack">{{ lang.old_balance }}</th>
                                    <th class="text-center backGreen">{{ lang.income }}</th>
                                    <th class="text-center backRed">{{ lang.expense }}</th>
                                    <th class="text-center backRed" v-if="parseFloat(item.total) < 0">{{ lang.period_balance }}</th>
                                    <th class="text-center backGreen" v-else>{{ lang.period_balance }}</th>
                                    <th class="text-center backBlack">{{ lang.balance }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center backBlack">{{ item[`name_`+lang.langname] }}</td>
                                    <td class="text-center">{{ $convertToComa($RoundNums(item.old_balance),comaMoney) }}</td>
                                    <td class="text-center">{{ $convertToComa($RoundNums(item.income),comaMoney) }}</td>
                                    <td class="text-center">{{ $convertToComa($RoundNums(item.outcome),comaMoney) }}</td>
                                    <td class="text-center">{{ $convertToComa($RoundNums(item.total),comaMoney) }}</td>
                                    <td class="text-center">{{ $convertToComa($RoundNums(parseFloat(item.old_balance) + parseFloat(item.total)),comaMoney) }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="12" sm="12" style="padding-top:0px;">
                <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;`" v-if="showSummary">
                    <v-col cols="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="backBlack" colspan="6">{{ lang.daily_summary_for_date }} {{ currentDate }} </th>
                                </tr>
                                <tr>
                                    <th class="text-center backBlack" style="width:100px">{{ lang.date }}</th>
                                    <th class="text-center backBlack" style="width:100px">{{ lang.time }}</th>
                                    <th class="text-center backBlack">{{ lang.description }}</th>
                                    <th class="text-center backBlack" style="width:130px">{{ lang.payment_type }}</th>
                                    <!-- <th class="text-center backBlack" style="width:100px">{{ lang.amount }}</th> -->
                                    <th class="text-center backGreen" style="width:100px">{{ lang.debet }}</th>
                                    <th class="text-center backRed" style="width:100px">{{ lang.credit }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in summary.rows" :key="index">
                                    <td class="text-center">{{ item.date }}</td>
                                    <td class="text-center">{{ item.time }}</td>
                                    <td>{{ item[`description_`+lang.langname] }}</td>
                                    <td class="text-center">{{ item[`payment_type_`+lang.langname] }}</td>
                                    <!-- <td class="text-center">{{ item.amount }}</td> -->
                                    <td class="text-center" style="background:lightgreen !important;">{{ item.debet }}</td>
                                    <td class="text-center" style="background:lightgreen !important;">{{ item.credit }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3" class="backEmpty"></th>
                                    <th class="text-end backBlack">{{ lang.total }}</th>
                                    <th class="text-center backGreen">{{ $convertToComa(summary.totals.debit,comaMoney) }}</th>
                                    <th class="text-center backRed">{{ $convertToComa(summary.totals.credit,comaMoney) }}</th>
                                </tr>
                                <tr>
                                    <th colspan="3" class="backEmpty"></th>
                                    <th class="text-end backBlack">{{ lang.today_balance }}</th>
                                    <th colspan="2" class="text-center backGreen" v-if="summary.current_balance >= 0">{{ $convertToComa(summary.current_balance,comaMoney) }}</th>
                                    <th colspan="2" class="text-center backRed" v-else>{{ $convertToComa(summary.current_balance,comaMoney) }}</th>
                                </tr>
                                <tr>
                                    <th colspan="3" class="backEmpty"></th>
                                    <th class="text-end backBlack">{{ lang.previews_balance }}</th>
                                    <th colspan="2" class="text-center backGreen" v-if="summary.previews_balance >= 0">{{ $convertToComa(summary.previews_balance,comaMoney) }}</th>
                                    <th colspan="2" class="text-center backRed" v-else>{{ $convertToComa(summary.previews_balance,comaMoney) }}</th>
                                </tr>
                                <tr>
                                    <th colspan="3" class="backEmpty"></th>
                                    <th class="text-end backBlack">{{ lang.total_balance }}</th>
                                    <th colspan="2" class="text-center backGreen" v-if="total_balance >= 0">{{ $convertToComa(total_balance,comaMoney) }}</th>
                                    <th colspan="2" class="text-center backRed" v-else>{{ $convertToComa(total_balance,comaMoney) }}</th>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6" v-for="(item,index) in summary.periodPayTypes" :key="index">
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center backBlack">{{ lang.payment_type }}</th>
                                            <th class="text-center backBlack">{{ lang.old_balance }}</th>
                                            <th class="text-center backGreen">{{ lang.income }}</th>
                                            <th class="text-center backRed">{{ lang.expense }}</th>
                                            <th class="text-center backRed" v-if="parseFloat(item.total) < 0">{{ lang.period_balance }}</th>
                                            <th class="text-center backGreen" v-else>{{ lang.period_balance }}</th>
                                            <th class="text-center backBlack">{{ lang.balance }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center backBlack">{{ item[`name_`+lang.langname] }}</td>
                                            <td class="text-center">{{ $convertToComa($RoundNums(item.old_balance),comaMoney) }}</td>
                                            <td class="text-center">{{ $convertToComa($RoundNums(item.income),comaMoney) }}</td>
                                            <td class="text-center">{{ $convertToComa($RoundNums(item.outcome),comaMoney) }}</td>
                                            <td class="text-center">{{ $convertToComa($RoundNums(item.total),comaMoney) }}</td>
                                            <td class="text-center">{{ $convertToComa($RoundNums(parseFloat(item.old_balance) + parseFloat(item.total)),comaMoney) }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        
        <vue-snotify></vue-snotify>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import posHeader from '@/components/pos-header.vue'
import {mapActions, mapGetters} from 'vuex'
import {SnotifyPosition} from 'vue-snotify';
export default{
  components: { HeaderPortrate,posHeader },
    name: 'POS',
    data() {
        return {
            showSummary: false,
            currentDate: '',
            items: {},
            // sdate: '',
            // edate: '',
            inoutRows: [],
            periodPayTypes: [],
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            summary: {
                rows: [],
                previews_balance: 0,
                current_balance: 0,
                totals: {
                    credit: 0,
                    debit: 0
                },
            }
        }
    },
    computed:{
        ...mapGetters({
            inOutState: `inOutReport/inOutState`
        }),
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        report: function () {
            return this.inOutState.report
        },
        comaMoney: function(){
            return this.$store.state.licenseType.moneyComma;
        },
        totals: function(){
            let t = {
                inTotal: 0,
                salaries: 0,
                credit_note: 0,
                emp_custody: 0,
                paid_in_advanced: 0,
                purchase: 0,
                outTotal: 0,
                cash: 0,
                others: 0,
                vendor_payments: 0
            }
            for(const item in this.report){
                t.inTotal = parseFloat(t.inTotal) + parseFloat(this.report[item].income);
                t.salaries = parseFloat(t.salaries) + parseFloat(this.report[item].salaries);
                t.emp_custody = parseFloat(t.emp_custody) + parseFloat(this.report[item].emp_custody);
                t.paid_in_advanced = parseFloat(t.paid_in_advanced) + parseFloat(this.report[item].paid_in_advanced);
                t.purchase = parseFloat(t.purchase) + parseFloat(this.report[item].purchase);
                t.outTotal = parseFloat(t.outTotal) + parseFloat(this.report[item].outcome);
                t.vendor_payments = parseFloat(t.vendor_payments) + parseFloat(this.report[item].vendor_payments);
                t.cash = parseFloat(t.cash) + parseFloat(this.report[item].cash);
                t.others = parseFloat(t.others) + parseFloat(this.report[item].others);
                t.credit_note = parseFloat(t.credit_note) + parseFloat(this.report[item].credit_note);
            }
            return {
                inTotal: this.$convertToComa(this.$RoundNums(t.inTotal),this.comaMoney),
                credit_note: this.$convertToComa(this.$RoundNums(t.credit_note),this.comaMoney),
                salaries: this.$convertToComa(this.$RoundNums(t.salaries),this.comaMoney),
                emp_custody: this.$convertToComa(this.$RoundNums(t.emp_custody),this.comaMoney),
                purchase: this.$convertToComa(this.$RoundNums(t.purchase),this.comaMoney),
                paid_in_advanced: this.$convertToComa(this.$RoundNums(t.paid_in_advanced),this.comaMoney),
                outTotal: this.$convertToComa(this.$RoundNums(t.outTotal),this.comaMoney),
                vendor_payments: this.$convertToComa(this.$RoundNums(t.vendor_payments),this.comaMoney),
                cash: this.$convertToComa(this.$RoundNums(t.cash),this.comaMoney),
                others: this.$convertToComa(this.$RoundNums(t.others),this.comaMoney),
            };
        },
        lastBalance: function(){
            return this.$convertToComa(this.$RoundNums(this.inOutState.lastBalance),this.comaMoney)
        },
        headers: function() {
            return [
                { text: this.lang.date },
                { text: this.lang.income },
                { text: this.lang.purchase },
                { text: this.lang.expnese },
                { text: this.lang.cash },
                { text: this.lang.other },
                { text: this.lang.balance },
                { text: this.lang.balance },
            ]
        },
        total_balance: function(){
            let t= 0;
            t = parseFloat(this.summary.previews_balance) + parseFloat(this.summary.current_balance);
            return this.$RoundNums(t,2);
        },
        
    },
    methods: {
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        async ViewItem(item){
            this.showSummary = true;
            this.currentDate = item.date;
            const post = new FormData();
            post.append('type','getDailySammary'); 
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[date]',item.date)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            
            if(response && response.data){
                this.summary = response.data.results.data;
            }else{
                console.log(response)
            }
        },
        preparedata(){
            let t = this.headers;
            let data = this.report;
            // console.log("hi it ",this.report)
            let hdata = [];
            let exdata = [];
            for(let i=0;i<t.length;i++){
                hdata.push(t[i].text);
            }
            exdata.push(hdata);
            exdata.push([
                "","","","",this.lang.old_balance,this.lastBalance
            ]);
            Object.values(data).forEach(value => {
                let x = [];
                x.push(value.date);
                x.push(value.income);
                x.push(value.purchase);
                x.push(value.outcome);
                x.push(value.cash);
                x.push(value.others);
                x.push(parseFloat(value.balance));
                exdata.push(x);
            })

            exdata.push([
                this.lang.total,this.totals.inTotal,this.totals.outTotal,this.totals.cash,this.totals.others,""
            ]);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.edate;
            this.$refs.exportbar.exporttitle = this.lang.income_expense_report;
            // if(this.selected == 1)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_new;
            // if(this.selected == 4)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_late;
            // if(this.selected == 3)
            //     this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_closed;
            // this.$refs.exportbar.palte_number = this.plate_number;
            // this.$refs.exportbar.contact_number = this.mobile;
        },
        ...mapActions({
            getInOutRep: `inOutReport/getInOutRep`
        }),
        getReport() {
            const ds = new Date(this.sdate);
            const de = new Date(this.edate);
            if(ds.getTime() > de.getTime()){
                let message = "يجب ان يكون تاريخ البدء اقل من او يساوي تاريخ الانتهاء";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                return false;
            }
            this.showSummary = false
            this.getInOutRep({sdate:this.sdate,edate:this.edate})
        },
        async exportE(e){
            const post = new FormData();
            post.append('export','incomereport');
            // console.log(this.report)
            let i = 0;
            for(const repo in this.report){
                
                for(const rep in this.report[repo]){
                    post.append('data['+i+']['+rep+']',this.report[repo][rep]);
                }
                i++;
            }
            post.append('sdate',this.sdate);
            post.append('edate',this.edate);
            post.append('startbalance',this.lastBalance);
            post.append('auth',this.$cookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/excel.php',post
            )

            if(response && response.data){
                window.open("/api/"+response.data.url)
            }
        }
    },
    created() {
        this.getStartDate();
        this.getInOutRep({sdate:this.sdate,edate:this.edate})
    },
}
</script>

<style>
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:100px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:25px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:green;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.border2black,.modal-content{
    border:2px solid #000 !important;
}
.positionEnd,.positionEnd:hover{
    background:transparent !important;
    border:none;
    box-shadow: none;
    color:#fff !important;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
.addButtom{
  position:absolute;
  text-align:center;
  left:0px;
  top:10px;
}
</style>